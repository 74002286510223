@font-face {
    font-family: 'KGMiss';
    src: local('GoldmanBold'), url('./assets/fonts/KGMissKindyChunky/KGMissKindyChunky.ttf') format('truetype');
}

body,
html {
    font-family: 'KGMiss';

    /* 👇️ or use Operating system default fonts 👇️ */
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}
::-webkit-scrollbar{
    display: none;
  }